import React from 'react';
import { Link } from 'react-router-dom';
import './Pages.css'; // Import your custom CSS

function Pages({ tag, apiData }) {
  if (!apiData) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  // Extract relevant pages from apiData based on the tag
  const filteredPages = Object.entries(apiData).filter(([title]) => title.includes(tag));

  return (
    <div className="container">
      <h2 className="title">{tag}</h2>
      <div className="row">
        {/* Map through the filtered pages and render one image per page */}
        {filteredPages.map(([title, paths], index) => (
          <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
            <Link to={`/${title.replace(/\s/g, '-')}`}>
              {/* Render one image with embedded link */}
              <img
                src={`https://PornUrgeofc.b-cdn.net/Images/${paths[0]}`} // Show the first image of the page
                alt={`${tag}-${index}`}
                className="rounded-image"
                style={{ width: '100%', height: 'auto' }}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pages;
