// Menu.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'; // Import CSS file

const Menu = () => {
  return (
    <div className="menu-container">
      <Link to="/"><b><h1 className="menu-title">PornUrge</h1></b></Link>
      
      <div className="menu">
        <ul>
          <li className="menu-item">
            <Link to="/">Home</Link>
          </li>
          <li className="menu-item">
            <Link to="/tags">Tags</Link>
          </li>
          {/* Add more menu items as needed */}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
