import React from 'react';
import { Link } from 'react-router-dom';
import './Tags.css'; // Import Tags.css file

const Tags = ({ apiData, tags }) => {
  return (
    <div className="container">
      <h2 className="title">Tags</h2>
      <div className="tags-container">
        {tags.map((tag, index) => (
          <div key={index} className="tag-item">
            <h3>{tag}</h3>
            <div className="tag-images">
              {apiData &&
                Object.entries(apiData).map(([title, paths]) => {
                  if (title.toLowerCase().includes(tag.toLowerCase())) {
                    const imageUrl = `https://PornUrgeofc.b-cdn.net/Images/${paths[0]}`;
                    return (
                      <div key={title} className="tag-image">
                        <Link to={`/${title.replace(/\s/g, '-')}`}>
                          <img src={imageUrl} alt={title} />
                        </Link>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
