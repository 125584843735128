import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import your custom CSS

function shuffle(array) {
  // Implementation of the Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function Home({ apiData, tags }) {
  // Check if apiData is null
  if (!apiData) {
    return <div>Loading...</div>; // Add loading indicator or handle the case where data is not available yet
  }

  // Extract one image path and title from each page in apiData
  const pages = Object.entries(apiData).map(([title, paths]) => {
    // Replace spaces with dashes in the title
    const formattedTitle = title.replace(/\s/g, '-');
    return { title: formattedTitle, path: paths[0] }; // Only take the first image path
  });

  // Shuffle the array of pages
  const shuffledPages = shuffle(pages);

  return (
    <div className="container">
      
      <div className="tags-list">
        {tags.map((tag, index) => (
          <Link key={index} to={`/${tag}`} className="tag-link">
            {tag}
          </Link>
        ))}
      </div>
      <h2 className="title"><b></b></h2>
      <div className="row">
        {shuffledPages.map(({ title, path }, index) => (
          <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
            <Link to={`/${title}`}>
              <img
                src={`https://PornUrgeofc.b-cdn.net/Images/${path}`}
                alt={title}
                className="rounded-image"
                style={{ width: '100%', height: 'auto' }}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
