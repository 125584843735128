import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Home';
import Menu from './Menu';
import Tags from './Tags';
import Pages from './Pages';
import './App.css';

function App() {
  const [apiData, setApiData] = useState(null);
  const tags = ['cute', 'gallery', 'Lingrie',  'blonde', 'porn', 'Hardcore','Ass','Teen','Blowjob','Big Tits','Pussy','Brunette','Panties','Blonde','Spreading','Babe','Lingerie','Milf','Shaved','Pornstars','Close Up','Legs','Amateurs','High Heels','Cumshot','Stockings','Tits','Masturbation','Nipples','Beautiful','Clothed','Anal','Mature','Tiny Tits','Ass Fucking','Outdoor','Skirt','Cowgirl']; // Example list of tags
  const specialTags = ['Victoria Zdrok', 'Bianca Alves', 'Hyley Winters', 'Tenshigao', 'Maribel Lopez', 'Mami Misato']; // Separate list of special tags

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.npoint.io/540f5d7962ae558edeed');
        
        if (response.ok) {
          const data = await response.json();
          setApiData(data);
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      // No clean-up needed in this case
    };
  }, []);

  const getPageTitle = () => {
    const pathname = window.location.pathname;
    const pageTitle = pathname.substring(1).replace(/-/g, ' '); // Replace dashes with spaces
    return pageTitle || 'Porn Urge'; // Default title
  };

  return (
    <Router>
      <div className="container">
        <Helmet>
          <title>{getPageTitle()}</title>
        </Helmet>
        <header>
          <Menu />
        </header>
        <Route exact path="/">
          {/* You removed Bars component here */}
        </Route>
        <main className="main-content">
          <Switch>
            <Route exact path="/">
              <Home apiData={apiData} tags={tags} />
            </Route>
            {apiData && Object.entries(apiData).map(([indent, imagePath], index) => (
              <Route key={index} path={`/${indent.replace(/\s/g, '-')}`}>
                <h2 className="font-weight-bold">{indent.replace(/_/g, ' ')}</h2>
                <div className="row">
                  {imagePath.map((path, idx) => (
                    <div key={idx} className="col-md-3 mb-4">
                      <img src={`https://PornUrgeofc.b-cdn.net/Images/${path}`} alt={`Image ${indent}-${idx}`} className="img-fluid" />
                    </div>
                  ))}
                </div>
                {/* You may need to adjust other components accordingly */}
              </Route>
            ))}
            <Route path="/tags">
              <Tags apiData={apiData} tags={tags} />
            </Route>
            {/* Route for specific tag pages */}
            {tags.map((tag, index) => (
              <Route key={index} path={`/${tag}`}>
                <Pages tag={tag} apiData={apiData} />
              </Route>
            ))}
            {/* Route for specific special tag pages */}
            {specialTags.map((tag, index) => (
              <Route key={index} path={`/${tag.replace(/ /g, '-')}`}>
                <Pages tag={tag} apiData={apiData} />
              </Route>
            ))}
          </Switch>
        </main>
        <footer>
          {/* Footer content */}
        </footer>
      </div>
    </Router>
  );
}

export default App;
